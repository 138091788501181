import { CommonDialog } from './CommonDialog'
import React, { Dispatch, SetStateAction } from 'react'

interface IErrorProps {
  errorDia: boolean
  setErrorDia: Dispatch<SetStateAction<boolean>>
  errorMsg: string
  errorHeaderMsg?: string
  isApprovalPolicy?: boolean
  onclick?: any
  isProceed?: boolean
}

export const ErrorDialog = ({
  errorDia,
  setErrorDia,
  errorMsg,
  errorHeaderMsg,
  isApprovalPolicy = false,
  onclick,
  isProceed,
}: IErrorProps) => {
  return (
    <CommonDialog
      fullWidth={true}
      open={errorDia}
      onClose={() => {
        isApprovalPolicy ? onclick() : setErrorDia(false)
      }}
      sections={{
        header: {
          title: !!errorHeaderMsg
            ? `${errorHeaderMsg}
              ${errorHeaderMsg?.includes('Error!') ? '' : '!'}`
            : 'Error!',
          style: { color: 'red' },
        },
        body: () => (
          <div className="content-wrap full text-noflow">
            <div className="mdDesc text-noflow">{errorMsg}</div>
          </div>
        ),
        footer: {
          actions: isProceed
            ? [
                {
                  displayText: 'Cancel',
                  props: {
                    onClick: () => setErrorDia(false),
                    variant: 'contained',
                    color: 'primary',
                  },
                },
                {
                  displayText: 'Proceed',
                  props: {
                    onClick: () =>
                      isApprovalPolicy ? onclick() : setErrorDia(false),
                    variant: 'contained',
                    color: 'primary',
                  },
                },
              ]
            : [
                {
                  displayText: 'Close',
                  props: {
                    onClick: () =>
                      isApprovalPolicy ? onclick() : setErrorDia(false),
                    variant: 'contained',
                    color: 'primary',
                  },
                },
              ],
        },
      }}
    />
  )
}
