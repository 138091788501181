import React, { lazy } from 'react';

const DiaryListing = lazy(() =>
  import('./Diary/DiaryListing').then(module => ({
    default: module.DiaryListing,
  })),
);

const MorningBriefingListing = lazy(() =>
  import('./MorningBriefing/MorningBriefingListing').then(module => ({
    default: module.MorningBriefingListing,
  })),
);

const MorningBrifiengForm = lazy(() =>
  import('./MorningBriefing/MorningBriefingForm').then(module => ({
    default: module.MorningBriefingForm,
  })),
);

const IncidentListing = lazy(() =>
  import('./Incident/IncidentListing').then(module => ({
    default: module.IncidentListing,
  })),
);

const IncidentForm = lazy(() =>
  import('./Incident/IncidentForm').then(module => ({
    default: module.IncidentForm,
  })),
);

const siteDiaryRoutes = [
  {
    props: { exact: true, path: '/morning-briefing' },
    component: <MorningBriefingListing />,
  },
  {
    props: { exact: true, path: '/morning-briefing/:siteID' },
    component: <MorningBriefingListing />,
  },
  {
    props: { exact: true, path: '/incident' },
    component: <IncidentListing />,
  },
  {
    props: { exact: true, path: '/incident/:siteID' },
    component: <IncidentListing />,
  },
  {
    props: { exact: true, path: '/incident/:siteID/add' },
    component: <IncidentForm mode="add" />,
  },
  {
    props: { exact: true, path: '/morning-briefing/:siteID/add' },
    component: <MorningBrifiengForm mode="add" />,
  },
  {
    props: { exact: true, path: '/:siteID/:diaryID' },
    component: <DiaryListing />,
  },
];

export default siteDiaryRoutes;
