import { useSitePermissionChecker } from '@contract-root/admin-react/src/helpers/Hooks/usePermissionChecker';
import GeneralSettingActive from '@ifca-root/react-component/src/assets/icons/general-setting-w.svg';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import {
  Avatar,
  IconButton,
  ListItemSecondaryAction,
  Menu,
  MenuItem,
} from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import ExpandMore from '@material-ui/icons/ExpandMore';
import GeneralSetting from 'assets/icons/SideMenu/general_settings.svg';
import AccessSecurity from '@ifca-root/react-component/src/assets/icons/SystemAdmin/access-sec.svg';
import Home from 'assets/icons/SideMenu/home.svg';
import Logout from 'assets/icons/SideMenu/logout.svg';
import Profile from 'assets/icons/SideMenu/user-profile.svg';
import HomeActive from 'assets/icons/home-w.svg';
import {
  useGetSiteDrawerPermissionLazyQuery,
  useLoggedInUserProfileLazyQuery,
} from 'generated/graphql';
import { useBroadcastChannel } from 'helpers/Hooks/useBroadcastChannel';
import React, { MouseEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { NavLink } from 'react-router-dom';
export const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    drawerPaper: {
      width: drawerWidth,
      [theme.breakpoints.up('sm')]: {
        top: 64,
        height: 'calc(100% - 64px)',
      },
      zIndex: 100,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    activeListItem: {
      borderLeft: '4px solid red',
      boxShadow: '0 3px 2px -3px gray',
      backgroundColor: 'blue',
      '& $listItemText': {
        color: 'white',
        marginTop: 0,
        marginBottom: 0,
        fontWeight: 500,
      },
      '& $listItemIcon': {
        color: 'white',
        marginLeft: '-4px',
        minWidth: '36px',
      },
    },
    listItemText: {
      fontSize: '1rem',
      fontWeight: 400,
      color: 'black',
      marginTop: 0,
      marginBottom: 0,
    },
  }),
);

interface Props {
  window?: () => Window;
  open?: boolean;
  variant?: any;
  onCloseDrawer?: any;
}

export default function LayoutDrawer(props: Props) {
  const { window, open, onCloseDrawer, variant, ...rest } = props;
  const classes = useStyles();
  const container =
    window !== undefined ? () => window().document.body : undefined;
  let history = useHistory();
  const { signOut } = useBroadcastChannel();
  const user = JSON.parse(localStorage.getItem('loggedInUser'));
  const entityID = localStorage.getItem('latestSite');
  const siteID = localStorage.getItem('siteID');

  const { handlePermHidden } = useSitePermissionChecker();

  const mode: any = history?.location?.pathname;

  let isHome = false,
    isHomeGroup = false;

  if (mode === '/home' || mode === '/alternate') {
    localStorage.removeItem('labelState');
    isHome = true;
  }
  if (mode === `/home/${siteID}` || mode === `/`) {
    localStorage.removeItem('labelState');
    isHomeGroup = true;
  }

  const checkSysAdmin = mode => {
    let k = ['/system-admin'].filter(k => mode?.includes(k));
    return k.length > 0;
  };

  const checkGenSettings = mode => {
    let k = ['/general-settings'].filter(k => mode?.includes(k));
    return k.length > 0;
  };

  const checkContApproval = mode => {
    let k = ['/site-inventory'].filter(k => mode?.includes(k));
    return k.length > 0;
  };

  const [
    fetchUserInfo,
    {
      loading,
      error,
      data: { loggedInUserProfile } = { loggedInUserProfile: null },
    },
  ] = useLoggedInUserProfileLazyQuery({
    fetchPolicy: 'network-only',
  });

  const [
    fetchDrawer,
    {
      loading: drawerLoading,
      error: drawerError,
      data: {
        getSiteDrawerPermission: {
          accessSecurity,
          generalSetting,
          contractSetting,
        },
      } = {
        getSiteDrawerPermission: {
          accessSecurity: false,
          generalSetting: false,
          contractSetting: false,
        },
      },
    },
  ] = useGetSiteDrawerPermissionLazyQuery({
    fetchPolicy: 'network-only',
  });

  const pageMode: any = reconsRoute(mode);

  const defaultProfileMenu = [
    {
      name: 'My Profile',
      path: '/profile',
      deployed: true,
      icon: Profile,
      iconSelected: Profile,
      permission: true,
    },
  ];

  const defaultLogoutMenu = [
    {
      name: 'Logout',
      path: '/logout',
      deployed: true,
      icon: Logout,
      iconSelected: Logout,
      permission: true,
    },
  ];

  const defaultHomeMenu = [
    {
      name: 'Home Page',
      path: !!siteID ? `/home/${siteID}` : '/',
      deployed: true,
      icon: Home,
      iconSelected: HomeActive,
      permission: true,
    },
  ];

  const defaultAccessSecurityMenu = [
    {
      name: 'Access Security',
      path: `/access-security`,
      deployed: true,
      icon: AccessSecurity,
      permission: accessSecurity,
      iconSelected: AccessSecurity,
    },
  ];

  const defaultGenSettingMenu = [
    {
      name: 'General Settings',
      path: `/defect/general-settings`,
      deployed: true,
      icon: GeneralSetting,
      permission: generalSetting,
      iconSelected: GeneralSettingActive,
    },
  ];

  const defaultConSettingsMenu = [
    {
      name: 'Contract Settings',
      path: `/${pageMode}/contract-settings`,
      deployed: true,
      icon: GeneralSetting,
      permission: contractSetting,
      iconSelected: GeneralSetting,
    },
  ];

  const navigations: any = isHome
    ? [...defaultProfileMenu, ...defaultLogoutMenu]
    : isHomeGroup
    ? [
        ...defaultHomeMenu,
        ...defaultAccessSecurityMenu,
        ...defaultGenSettingMenu,
        ...defaultLogoutMenu,
      ]
    : checkGenSettings(mode) && !checkContApproval(mode)
    ? [...defaultHomeMenu, ...defaultGenSettingMenu, ...defaultLogoutMenu]
    : checkContApproval(mode)
    ? [
        ...defaultHomeMenu,
        ...defaultGenSettingMenu,
        ...defaultConSettingsMenu,
        ...defaultLogoutMenu,
      ]
    : checkSysAdmin(mode)
    ? [
        ...defaultHomeMenu,
        ...defaultAccessSecurityMenu,
        ...defaultGenSettingMenu,
        ...defaultLogoutMenu,
      ]
    : [
        ...defaultHomeMenu,
        ...defaultAccessSecurityMenu,
        ...defaultGenSettingMenu,
        ...defaultLogoutMenu,
      ];
  // const navigations = isHome
  //   ? [
  //       {
  //         name: 'My Profile',
  //         path: '/profile',
  //         deployed: true,
  //         icon: Profile,
  //         iconSelected: Profile,
  //         permission: undefined,
  //       },
  //       {
  //         name: 'Logout',
  //         path: '/logout',
  //         deployed: true,
  //         icon: Logout,
  //         iconSelected: Logout,
  //         permission: undefined,
  //       },
  //     ]
  //   : checkSysAdmin(mode)
  //   ? [
  //       {
  //         name: 'Home Page',
  //         path: `/${siteID}`,
  //         deployed: true,
  //         icon: Home,
  //         iconSelected: HomeActive,
  //         permission: ContractPermission.HomeExecSummaryView,
  //       },
  //       {
  //         name: 'General Settings',
  //         path: `/defect/general-settings`,
  //         deployed: true,
  //         icon: GeneralSetting,
  //         permission: ContractPermission.GeneralSettingExecSummaryView,
  //         iconSelected: GeneralSettingActive,
  //       },
  //       mode?.includes('/site-inventory')
  //         ? {
  //             name: 'Contract Settings',
  //             path: `/${pageMode}/contract-settings`,
  //             deployed: true,
  //             icon: GeneralSetting,
  //             permission: ContractPermission.GeneralSettingExecSummaryView,
  //             iconSelected: GeneralSettingActive,
  //           }
  //         : {},
  //       {
  //         name: 'Logout',
  //         path: '/logout',
  //         deployed: true,
  //         icon: Logout,
  //         iconSelected: Logout,
  //         permission: undefined,
  //       },
  //     ]
  //   : mode === '/profile'
  //   ? [
  //       {
  //         name: 'Home Page',
  //         path: '/home',
  //         deployed: true,
  //         icon: Home,
  //         iconSelected: HomeActive,
  //         permission: ContractPermission.HomeExecSummaryView,
  //       },
  //     ]
  //   : isHomeGroup
  //   ? [
  //       {
  //         name: 'Home Page',
  //         path: `/${siteID}`,
  //         deployed: true,
  //         icon: Home,
  //         iconSelected: HomeActive,
  //         permission: ContractPermission.HomeExecSummaryView,
  //       },
  //       {
  //         name: 'General Settings',
  //         path: `/defect/general-settings`,
  //         deployed: true,
  //         icon: GeneralSetting,
  //         permission: ContractPermission.GeneralSettingExecSummaryView,
  //         iconSelected: GeneralSettingActive,
  //       },
  //       {
  //         name: 'Logout',
  //         path: '/logout',
  //         deployed: true,
  //         icon: Logout,
  //         iconSelected: Logout,
  //         permission: undefined,
  //       },
  //     ]
  //   : checkGenSettings(mode)
  //   ? [
  //       [
  //         {
  //           name: 'Home Page',
  //           path: `/${siteID}`,
  //           deployed: true,
  //           icon: Home,
  //           iconSelected: HomeActive,
  //           permission: ContractPermission.HomeExecSummaryView,
  //         },
  //         {
  //           name: 'Logout',
  //           path: '/logout',
  //           deployed: true,
  //           icon: Logout,
  //           iconSelected: Logout,
  //           permission: undefined,
  //         },
  //       ],
  //     ]
  //   : [
  //       [
  //         {
  //           name: 'Home Page',
  //           path: `/${siteID}`,
  //           deployed: true,
  //           icon: Home,
  //           iconSelected: HomeActive,
  //           permission: ContractPermission.HomeExecSummaryView,
  //         },
  //         {
  //           name: 'Logout',
  //           path: '/logout',
  //           deployed: true,
  //           icon: Logout,
  //           iconSelected: Logout,
  //           permission: undefined,
  //         },
  //       ],
  //     ];

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleProfileClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleRedirectProfile = () => {
    if (!!onCloseDrawer) {
      onCloseDrawer();
    }
    setAnchorEl(null);
    history.push('/profile');
  };

  useEffect(() => {
    if (!user?.ID) {
      fetchUserInfo();
    }
  }, [user?.ID]);

  useEffect(() => {
    if (mode === `/home/${siteID}` || mode === `/` || pageMode) {
      fetchDrawer({ variables: { pageMode: pageMode } });
    }
  }, [mode]);

  const drawer = (
    <div className="route-container">
      <List disablePadding>
        {navigations.map((v, index) => {
          if (!!v?.deployed && v?.permission)
            return (
              <ListItem
                button
                key={index}
                dense
                activeClassName={'active'}
                divider
                component={NavLink}
                to={{ pathname: v?.path }}
                onClick={() => {
                  if (!!onCloseDrawer) {
                    onCloseDrawer();
                  }
                  if (v?.name === 'Logout') {
                    signOut(
                      user?.ID ?? loggedInUserProfile?.ID,
                      entityID ?? user?.lastestAccessedEntity,
                    );
                    sessionStorage.clear();
                    sessionStorage.removeItem('tokenKey');
                  }
                }}
              >
                <ListItemIcon>
                  <img className="icon-svg" src={v?.icon} alt="drawer-icon" />
                </ListItemIcon>
                <ListItemText primary={v?.name} />
              </ListItem>
            );
        })}
      </List>
    </div>
  );

  // User Profile Wrapper
  const userProfile = (
    <div className="profile-container">
      <div className="profile-avatar">
        {!!user?.avatar ? (
          <img src={user?.avatar} alt="" className="avatar" />
        ) : !user?.avatar && !!loggedInUserProfile?.avatar ? (
          <img src={loggedInUserProfile?.avatar} alt="" className="avatar" />
        ) : (
          <Avatar alt="avatar" className="avatar" />
        )}
      </div>
      <List className="profile-list" disablePadding>
        <ListItem>
          <ListItemText
            primary={
              <span className="smTitle">
                {user?.userName || loggedInUserProfile?.userName}
              </span>
            }
            secondary={
              <span className="desc">
                {user?.email || loggedInUserProfile?.email}
              </span>
            }
          />
          {isHome ? null : (
            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                aria-label="simple-menu"
                onClick={handleProfileClick}
              >
                <ExpandMore />
              </IconButton>
            </ListItemSecondaryAction>
          )}
        </ListItem>
      </List>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className="drawer-submenu"
      >
        <MenuItem className="drawer-dropdown" onClick={handleRedirectProfile}>
          <img className="icon-dropdown-svg" src={Profile} alt="" /> Profile
        </MenuItem>
        <MenuItem
          className="drawer-dropdown"
          onClick={() => {
            signOut(
              user?.ID || loggedInUserProfile?.ID,
              entityID ?? user?.lastestAccessedEntity,
            );
            sessionStorage.clear();
            sessionStorage.removeItem('tokenKey');
          }}
        >
          <img className="icon-dropdown-svg" src={Logout} alt="" />
          Logout
        </MenuItem>
      </Menu>
    </div>
  );

  return (
    <>
      {loading && <Loading />}
      {drawerLoading && <Loading />}
      <nav className={classes.drawer} aria-label="main menu">
        <Drawer
          {...rest}
          container={container}
          variant={variant}
          anchor={'left'}
          open={open}
          onClose={onCloseDrawer}
          className="drawer"
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {userProfile}
          {drawer}
        </Drawer>
      </nav>
    </>
  );
}

// order of this is important
const mode = [
  'system-admin',
  'defect',
  'defect-management',
  'site-attendance',
  'site-inventory',
  'site-wages',
  'site-surveillance',
  'site-management',
  'business-insight',
  // 'to-do',
];

export const isIn = (route: string, module: string) => {
  return route.match(module);
};

export const reconsRoute = (route: string) => {
  let genRoute = mode.filter(x => isIn(route, x));
  return genRoute.length > 0 ? genRoute[genRoute.length - 1] : null;
};
