import { ContPermission, SitePermission } from 'generated/graphql';
import localForage from 'localforage';
import { useEffect, useState } from 'react';
/**
 * @param handlePermDisabled : {contractID: '' | contractID , permEnum: ContractPermission.HomeContractView}
 * @param handlePermHidden : {contractID: '' | contractID , permEnum: ContractPermission.HomeContractView}
 * @param permission : general permission list
 * @param contractPermission : contract permission list
 */
export interface PermProps {
  contractID?: string;
  permEnum: ContPermission | SitePermission;
  userDetail?: { ID: string; name: string; superUser: boolean };
  getRolePerm?: any;
}
export const usePermissionChecker = () => {
  const [permission, setPermMatch] = useState({});
  const [contractPermission, setContractPerm] = useState({});
  const user = JSON.parse(localStorage.getItem('loggedInUser'));

  useEffect(() => {
    localForage?.getItem('permission', (err, value) => {
      setPermMatch(value);
    });
  }, [setPermMatch]);

  useEffect(() => {
    localForage?.getItem('contractPermission', (err, value) => {
      setContractPerm(value);
    });
  }, [setContractPerm]);

  const handlePermDisabled = ({
    contractID,
    permEnum,
    userDetail,
  }: PermProps) => {
    return !!contractID && !!contractPermission
      ? contractPermission[contractID]?.hasOwnProperty(permEnum) === false &&
          !user?.superUser
      : permission?.hasOwnProperty(permEnum) === false && !user?.superUser;
  };

  const handlePermHidden = ({
    contractID,
    permEnum,
    userDetail,
    getRolePerm,
  }: PermProps) => {
    let userChecker = !!user?.ID ? user : userDetail;
    let rolePerm = !!permission ? permission : getRolePerm;

    return !userChecker?.superUser
      ? !!contractID && !!contractPermission
        ? contractPermission[contractID]?.hasOwnProperty(permEnum)
        : rolePerm?.hasOwnProperty(permEnum)
      : true;
  };

  return {
    permission,
    contractPermission,
    handlePermDisabled,
    handlePermHidden,
  };
};

interface PermSiteProps {
  contractID?: string;
  permEnum: ContPermission | SitePermission;
}
export const useSitePermissionChecker = () => {
  const [permission, setPermMatch] = useState({});
  const [sitePermission, setSitePerm] = useState({});
  const user = JSON.parse(localStorage.getItem('loggedInUser'));

  useEffect(() => {
    localForage?.getItem('rolePermission', (err, value) => {
      setPermMatch(value);
      // console.log(value);
    });
  }, [setPermMatch]);
  useEffect(() => {
    localForage?.getItem('sitePermission', (err, value) => {
      setSitePerm(value);
      // console.log(value);
    });
  }, [setSitePerm]);

  // const handlePermDisabled = ({ contractID, permEnum }: PermProps) => {
  //   return !!contractID
  //     ? sitePermission[contractID]?.hasOwnProperty(permEnum) ||
  //         user?.superUser
  //     : permission?.hasOwnProperty(permEnum) || user?.superUser;
  // };
  const handlePermDisabled = ({ contractID, permEnum }: PermSiteProps) => {
    // console.log('here', sitePermission);
    return !!contractID && !!sitePermission
      ? sitePermission[contractID]?.hasOwnProperty(permEnum) === false &&
          !user?.superUser
      : permission?.hasOwnProperty(permEnum) === false && !user?.superUser;
  };

  const handlePermHidden = ({ contractID, permEnum }: PermSiteProps) => {
    return contractID
      ? sitePermission[contractID]?.hasOwnProperty(permEnum) || user?.superUser
      : permission?.hasOwnProperty(permEnum) || user?.superUser;
  };
  return {
    permission,
    sitePermission,
    handlePermDisabled,
    handlePermHidden,
  };
};
